import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useQuery from 'src/hooks/common/useQuery';

import GATracker from 'src/utils/GATracker';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import PaymentAuthenticationFailure from 'src/components/payment/PaymentAuthenticationFailure';
import AppTemplate from '../templates/AppTemplate';
import Authenticated from 'src/containers/common/Authenticated';

const GetProcessingPaymentIntentQuery = `query getProcessingPaymentIntent($id: ID!) {
  processingPaymentIntent(id: $id) { id status redirectUrl }
}`;

interface GetProcessingPaymentIntentParams {
  id: string;
}

interface GetProcessingPaymentIntentResult {
  processingPaymentIntent: {
    id: string;
    status: string;
    redirectUrl: string | null;
  } | null;
}

const ConfirmBookCouponQuery = `mutation confirmBookOrder($staffId: ID!, $paymentIntentId: ID) {
  confirmBookOrder(input: { staffId: $staffId, paymentIntentId: $paymentIntentId }) {
    success
  }
}`;

interface ConfirmBookCouponParams {
  staffId: string;
  paymentIntentId: string | null;
}

interface ConfirmBookCouponResult {
  confirmBookOrder: {
    success: boolean;
  };
}

const OrderPaymentCallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const staffId = searchParams.get('staff_id');
  const paymentIntentId = searchParams.get('payment_intent') || null;

  const {
    executeQuery: fetchProcessingPaymentIntent,
    isLoading: isFetching,
    error: fetchError,
  } = useQuery<GetProcessingPaymentIntentParams, GetProcessingPaymentIntentResult>(GetProcessingPaymentIntentQuery);

  const {
    executeQuery: confirmBookOrder,
    isLoading: isConfirming,
    error: confirmError,
  } = useQuery<ConfirmBookCouponParams, ConfirmBookCouponResult>(ConfirmBookCouponQuery);

  useEffect(() => {
    if (!staffId) return;

    const run = async () => {
      if (paymentIntentId) {
        const fetchResult = await fetchProcessingPaymentIntent({ id: paymentIntentId });

        if (fetchResult.processingPaymentIntent?.status !== 'succeeded') return;
      }

      const confirmResult = await confirmBookOrder({ staffId, paymentIntentId });

      if (confirmResult.confirmBookOrder.success) {
        GATracker.trackEvent('purchase');
        navigate(`/staffs/${staffId}/orders/thanks`);
      }

      // TODO: implement for the case of required_payment_method status
      // call API to confirm payment intent again
    };

    run();
  }, [fetchProcessingPaymentIntent, confirmBookOrder, navigate, staffId, paymentIntentId]);

  const onClickRetry = () => {
    navigate(`/staffs/${staffId}/orders/payment`);
  };

  return (
    <AppTemplate>
      <Authenticated>
        <UiStackTemplate isLoading={isFetching || isConfirming} error={fetchError ?? confirmError}>
          {staffId && <PaymentAuthenticationFailure onClick={onClickRetry} />}
        </UiStackTemplate>
      </Authenticated>
    </AppTemplate>
  );
};

export default OrderPaymentCallbackPage;
