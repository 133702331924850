import { Karte } from './karte';
import { StaffIntroduction } from './staff';
import { User } from './user';

export enum SelectedBookStatus {
  Reviewing = 'reviewing',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface SelectedBook {
  id: string;
  title: string;
  isbn: string;
  status: SelectedBookStatus;
  rejectedReason: string | null;
  createdAt: string;
}

interface ApprovedSelectedBook extends SelectedBook {
  status: SelectedBookStatus.Approved;
  rejectedReason: null;
}

export interface RejectedSelectedBook extends SelectedBook {
  status: SelectedBookStatus.Rejected;
  rejectedReason: string;
}

export interface SelectedBookFieldValue {
  id?: string;
  title: string;
  isbn: string;
  status?: SelectedBookStatus;
}

export const isApprovedBook = (selectedBook: SelectedBook): selectedBook is ApprovedSelectedBook =>
  selectedBook.status === SelectedBookStatus.Approved;

export const isRejectedBook = (
  selectedBook: SelectedBook | SelectedBookFieldValue,
): selectedBook is RejectedSelectedBook => selectedBook.status === SelectedBookStatus.Rejected;

export interface BookRejection {
  id: string;
  reason: string;
}

export enum BookOrderStatus {
  Delivered = 'delivered',
  Approved = 'approved',
  Rejected = 'rejected',
  Listed = 'listed',
  Ordered = 'ordered',
  Draft = 'draft',
}

export const BookOrderStatusLabel: { [key in BookOrderStatus]: string } = {
  draft: '未注文',
  ordered: '注文済',
  listed: '未承認',
  rejected: '選書中',
  approved: '配送待ち',
  delivered: '配送済',
};

interface BookOrderBase {
  id: string;
  staff: StaffIntroduction;
  karte: Karte;
  user: User | null;
  selectedBooks: SelectedBook[];
  orderedAt: string | null;
  listedAt: string | null;
  rejectedAt: string | null;
  approvedAt: string | null;
  deliveredAt: string | null;
  createdAt: string;
  shipmentCode: string | null;
}

export interface DraftBookOrder extends BookOrderBase {
  status: BookOrderStatus.Draft;
}

export const isDraftBookOrder = (bookOrder: BookOrder): bookOrder is DraftBookOrder =>
  bookOrder.status === BookOrderStatus.Draft;

export interface OrderedBookOrder extends BookOrderBase {
  status: BookOrderStatus.Ordered;
  orderedAt: string;
}

export const isOrderedBookOrder = (bookOrder: BookOrder): bookOrder is OrderedBookOrder =>
  bookOrder.status === BookOrderStatus.Ordered;

export interface ListedBookOrder extends BookOrderBase {
  status: BookOrderStatus.Listed;
  orderedAt: string;
  listedAt: string;
}

export const isListedBookOrder = (bookOrder: BookOrder): bookOrder is ListedBookOrder =>
  bookOrder.status === BookOrderStatus.Listed;

export interface RejectedBookOrder extends BookOrderBase {
  status: BookOrderStatus.Rejected;
  orderedAt: string;
  listedAt: string;
  rejectedAt: string;
}

export const isRejectedBookOrder = (bookOrder: BookOrder): bookOrder is RejectedBookOrder =>
  bookOrder.status === BookOrderStatus.Rejected;

export interface ApprovedBookOrder extends BookOrderBase {
  status: BookOrderStatus.Approved;
  orderedAt: string;
  listedAt: string;
  approvedAt: string;
}

export const isApprovedBookOrder = (bookOrder: BookOrder): bookOrder is ApprovedBookOrder =>
  bookOrder.status === BookOrderStatus.Approved;

export interface DeliveredBookOrder extends BookOrderBase {
  status: BookOrderStatus.Delivered;
  orderedAt: string;
  listedAt: string;
  approvedAt: string;
  deliveredAt: string;
  shipmentCode: string;
}

export const isDeliveredBookOrder = (bookOrder: BookOrder): bookOrder is DeliveredBookOrder =>
  bookOrder.status === BookOrderStatus.Delivered;

export type BookOrder =
  | DraftBookOrder
  | OrderedBookOrder
  | ListedBookOrder
  | RejectedBookOrder
  | ApprovedBookOrder
  | DeliveredBookOrder;

export type BookCouponShipmentType = 'email' | 'self' | 'other';

export interface ShippingAddress {
  postalCode: string;
  address: string;
}

export interface DraftBookCouponLetterEmailType {
  id: string;
  shipmentType: 'email';
  recipientName: string;
  email: string;
  message: string | null;
}

export interface DraftBookCouponLetterSelfType {
  id: string;
  shipmentType: 'self';
  recipientName: null;
  email: null;
  shippingAddress: ShippingAddress;
}

export interface DraftBookCouponLetterOtherType {
  id: string;
  shipmentType: 'other';
  recipientName: string;
  email: null;
  message: string | null;
  shippingAddress: ShippingAddress;
}

export type DraftBookCouponLetter =
  | DraftBookCouponLetterEmailType
  | DraftBookCouponLetterSelfType
  | DraftBookCouponLetterOtherType;

export interface BookCoupon {
  id: string;
  code: string;
  isActive: boolean;
  issuer: { id: string; name: string } | null;
  recipient: { id: string; name: string } | null;
  bookOrder: { id: string; orderedAt: string | null } | null;
  createdAt: string;
  expiresAt: string;
}
