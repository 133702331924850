import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import useQuery from 'src/hooks/common/useQuery';
import {
  GET_DRAFT_BOOK_COUPON_LETTER_QUERY,
  GetDraftBookCouponLetterParams,
  GetDraftBookCouponLetterResult,
} from 'src/actions/queries/orderQueries';
import AppTemplate from 'src/components/templates/AppTemplate';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import Button from 'src/components/atoms/button/Button';
import { BookCouponDetailTable } from 'src/components/gifts/BookCouponDetail';

import CSSModule from './ThanksPage.module.scss';

const ThanksPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    isLoading: isFetching,
    error: fetchError,
    result: fetchResult,
    executeQuery: fetchDraftBookCouponLetter,
  } = useQuery<GetDraftBookCouponLetterParams, GetDraftBookCouponLetterResult>(GET_DRAFT_BOOK_COUPON_LETTER_QUERY);

  const draftId = searchParams.get('draftId');
  const draftBookCouponLetter = fetchResult?.draftBookCouponLetter ?? null;

  useEffect(() => {
    fetchDraftBookCouponLetter({ id: draftId ?? '' });
  }, [fetchDraftBookCouponLetter, draftId]);

  const onClick = () => navigate('/mypage');

  return (
    <AppTemplate>
      <UiStackTemplate isLoading={isFetching} error={fetchError}>
        {draftBookCouponLetter && (
          <div className={CSSModule.ThanksPage}>
            <h2 className={CSSModule.ThanksPage__Title}>
              {draftBookCouponLetter.shipmentType === 'email'
                ? 'ギフトを送信しました'
                : 'ギフトの郵送を受付完了しました'}
            </h2>
            <BookCouponDetailTable draftBookCouponLetter={draftBookCouponLetter} />
            <Button onClick={onClick}>マイページTOPへ</Button>
          </div>
        )}
      </UiStackTemplate>
    </AppTemplate>
  );
};

export default ThanksPage;
