import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Cookies from 'js-cookie';

import { User } from '../interfaces/user';
import {
  login,
  fetchMe,
  register,
  updateUser,
  fetchUserPresignedUrl,
  uploadUserPhoto,
  logout,
  unregisterUser,
  updateCurrentUser,
} from '../actions/auth';
import { TokenKey } from '../app.constants';

export interface AuthState {
  user: User | null;
  token?: string;
  isRegistering: boolean;
  isUnregistering: boolean;
  isUpdating: boolean;
  isFetchingToken: boolean;
  isFetchingMe: boolean;
  isFetchingUserPresignedUrl: boolean;
  isUploadingUserPhoto: boolean;
  registeringError: Error | null;
  unregisteringError: Error | null;
  updatingError: Error | null;
  fetchingTokenError: Error | null;
  fetchingMeError: Error | null;
  fetchingUserPresignedUrlError: Error | null;
  uploadingUserPhotoError: Error | null;
}

const initialState: AuthState = {
  user: null,
  token: Cookies.get(TokenKey),
  isRegistering: false,
  isUnregistering: false,
  isUpdating: false,
  isFetchingToken: false,
  isFetchingMe: false,
  isFetchingUserPresignedUrl: false,
  isUploadingUserPhoto: false,
  registeringError: null,
  unregisteringError: null,
  updatingError: null,
  fetchingTokenError: null,
  fetchingMeError: null,
  fetchingUserPresignedUrlError: null,
  uploadingUserPhotoError: null,
};

export const setToken = (token?: string | null) => {
  if (token) {
    Cookies.set(TokenKey, token);
  } else {
    Cookies.remove(TokenKey);
  }
};

export default reducerWithInitialState(initialState)
  .case(register.async.started, state => ({
    ...state,
    isRegistering: true,
    registeringError: null,
  }))
  .case(register.async.failed, (state, { error }) => ({
    ...state,
    isRegistering: false,
    registeringError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(register.async.done, (state, { result: { token } }) => {
    setToken(token);
    return {
      ...state,
      isRegistering: false,
      token,
    };
  })
  .case(updateUser.async.started, state => ({
    ...state,
    isUpdating: true,
    updatingError: null,
  }))
  .case(updateUser.async.failed, (state, { error }) => ({
    ...state,
    isUpdating: false,
    updatingError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(updateUser.async.done, (state, { result: { user } }) => ({
    ...state,
    isUpdating: false,
    user,
  }))
  .case(login.async.started, state => ({
    ...state,
    isFetchingToken: true,
    fetchingTokenError: null,
  }))
  .case(login.async.failed, (state, { error }) => ({
    ...state,
    isFetchingToken: false,
    fetchingTokenError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(login.async.done, (state, { result: { token } }) => {
    setToken(token);
    return {
      ...state,
      isFetchingToken: false,
      token,
    };
  })
  .case(logout, state => {
    setToken(null);
    return {
      ...state,
      token: undefined,
      user: null,
    };
  })
  .case(unregisterUser.async.started, state => ({
    ...state,
    isUnregistering: true,
    unregisteringError: null,
  }))
  .case(unregisterUser.async.failed, (state, { error }) => ({
    ...state,
    isUnregistering: false,
    unregisteringError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(unregisterUser.async.done, state => {
    setToken(null);
    return {
      ...state,
      isUnregistering: false,
      token: undefined,
      user: null,
    };
  })
  .case(fetchMe.async.started, state => ({
    ...state,
    isFetchingMe: true,
    fetchingMeError: null,
  }))
  .case(fetchMe.async.failed, (state, { error }) => ({
    ...state,
    isFetchingMe: false,
    fetchingMeError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchMe.async.done, (state, { result: { me } }) => {
    if (!me) {
      setToken(null);
    }
    return {
      ...state,
      isFetchingMe: false,
      user: me,
      token: me ? state.token : undefined,
    };
  })
  .case(fetchUserPresignedUrl.async.started, state => ({
    ...state,
    isFetchingUserPresignedUrl: true,
    fetchingUserPresignedUrlError: null,
  }))
  .case(fetchUserPresignedUrl.async.failed, (state, { error }) => ({
    ...state,
    isFetchingUserPresignedUrl: false,
    fetchingUserPresignedUrlError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(fetchUserPresignedUrl.async.done, state => ({
    ...state,
    isFetchingUserPresignedUrl: false,
  }))
  .case(uploadUserPhoto.async.started, state => ({
    ...state,
    isUploadingUserPhoto: true,
    uploadingUserPhotoError: null,
  }))
  .case(uploadUserPhoto.async.failed, (state, { error }) => ({
    ...state,
    isUploadingUserPhoto: false,
    uploadingUserPhotoError: error instanceof Error ? error : new Error('unexpected error'),
  }))
  .case(uploadUserPhoto.async.done, state => ({
    ...state,
    isUploadingUserPhoto: false,
  }))
  .case(updateCurrentUser, (state, { user }) => ({
    ...state,
    user,
  }));
