import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import useQuery from 'src/hooks/common/useQuery';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import BookCouponList from 'src/components/admin/bookCoupons/BookCouponList';
import { BookCoupon } from 'src/interfaces/order';
import Pagination from 'src/components/common/Pagination';

const GET_BOOK_COUPONS_QUERY = `query getBookCoupons($page: Int, $size: Int) {
  bookCoupons(page: $page, size: $size) {
    id
    code
    isActive
    issuer { id name }
    recipient { id name }
    bookOrder { id orderedAt }
    createdAt
    expiresAt
  }
  bookCouponCount
}`;

interface GetBookCouponsParams {
  page: number;
  size: number;
}

interface GetBookCouponsResult {
  bookCoupons: BookCoupon[];
  bookCouponCount: number;
}

const DEFAULT_PAGE_POSITION = 1;
const DEFAULT_PAGE_SIZE = 10;

const AdminGiftsContainer: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page') ?? DEFAULT_PAGE_POSITION);
  const size = Number(searchParams.get('size') ?? DEFAULT_PAGE_SIZE);

  const {
    isLoading,
    error: fetchError,
    result: fetchResult,
    executeQuery: fetchBookCoupons,
  } = useQuery<GetBookCouponsParams, GetBookCouponsResult>(GET_BOOK_COUPONS_QUERY);

  const totalCount = fetchResult?.bookCouponCount ?? 0;

  const onChangePage = (newPage: number) => {
    setSearchParams(params => {
      params.set('page', `${newPage}`);
      return params;
    });
  };

  const bookCoupons = fetchResult?.bookCoupons ?? [];

  useEffect(() => {
    fetchBookCoupons({ page, size });
  }, [fetchBookCoupons, page, size]);

  return (
    <UiStackTemplate isLoading={isLoading} isEmpty={bookCoupons.length === 0} error={fetchError}>
      <BookCouponList bookCoupons={bookCoupons} />
      <Pagination page={page} size={size} totalCount={totalCount} onChangePage={onChangePage} />
    </UiStackTemplate>
  );
};

export default AdminGiftsContainer;
