import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import API from './API';
import {
  FETCH_ORDERS,
  FETCH_ORDER_DETAIL,
  FETCH_DRAFT_ORDER,
  FETCH_NOTIFIABLE_ORDER_COUNT,
  REVIEW_SELECTED_BOOKS,
} from './actionTypes';
import { BookOrder, BookOrderStatus, BookRejection } from '../interfaces/order';
import {
  getBookOrdersQuery,
  getBookOrderDetailQuery,
  getDraftBookOrderQuery,
  getNotifiableOrderCountQuery,
  reviewSelectedBooksQuery,
} from './queries/orderQueries';

const actionCreator = actionCreatorFactory();
const asyncActionCreator = asyncFactory(actionCreator);

interface FetchOrdersParams {
  userId: string;
  page: number;
  size: number;
}

interface FetchOrdersResult {
  bookOrders: BookOrder[];
  totalCount: number;
}

export const fetchBookOrders = asyncActionCreator<FetchOrdersParams, FetchOrdersResult>(
  FETCH_ORDERS,
  async ({ userId, page, size }) => {
    const { bookOrders, bookOrderCount } = await API.query(getBookOrdersQuery, { userId, page, size });
    return { bookOrders, totalCount: bookOrderCount };
  },
);

interface FetchBookOrderDetailParams {
  bookOrderId: string;
}

interface FetchBookOrderDetailResult {
  bookOrder: BookOrder;
}

export const fetchBookOrderDetail = asyncActionCreator<FetchBookOrderDetailParams, FetchBookOrderDetailResult>(
  FETCH_ORDER_DETAIL,
  async ({ bookOrderId }) => API.query(getBookOrderDetailQuery, { bookOrderId }),
);

interface FetchDraftOrderParams {
  userId: string;
  staffId: string;
}

interface FetchDraftOrderResult {
  bookOrder: BookOrder | null;
}

export const fetchDraftOrder = asyncActionCreator<FetchDraftOrderParams, FetchDraftOrderResult>(
  FETCH_DRAFT_ORDER,
  async ({ userId, staffId }) => {
    const { bookOrders } = await API.query(getDraftBookOrderQuery, {
      userId,
      staffId,
      status: [BookOrderStatus.Draft],
      size: 1,
    });
    return { bookOrder: bookOrders[0] || null };
  },
);

interface FetchNotifiableOrderCountParams {
  userId: string;
}

export const fetchNotifiableOrderCount = asyncActionCreator<FetchNotifiableOrderCountParams, number>(
  FETCH_NOTIFIABLE_ORDER_COUNT,
  async ({ userId }) => {
    const { bookOrderCount: result } = await API.query(getNotifiableOrderCountQuery, {
      userId,
      status: [BookOrderStatus.Draft, BookOrderStatus.Listed],
    });
    return result;
  },
);

interface ReviewSelectedBooksParams {
  bookOrderId: string;
  approvedBookIds: string[];
  rejections: BookRejection[];
}

interface ReviewSelectedBookResult {
  status: BookOrderStatus;
}

export const reviewSelectedBooks = asyncActionCreator<ReviewSelectedBooksParams, ReviewSelectedBookResult>(
  REVIEW_SELECTED_BOOKS,
  async params => {
    const { rejectSelectedBooks: result } = await API.query(reviewSelectedBooksQuery, params);
    return result;
  },
);
