import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useMutation from 'src/hooks/common/useMutation';

import GATracker from 'src/utils/GATracker';
import useToast from 'src/hooks/store/useToast';
import AppTemplate from 'src/components/templates/AppTemplate';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import PaymentAuthenticationFailure from 'src/components/payment/PaymentAuthenticationFailure';
import useQuery from 'src/hooks/common/useQuery';
import {
  GET_DRAFT_BOOK_COUPON_LETTER_QUERY,
  GetDraftBookCouponLetterParams,
  GetDraftBookCouponLetterResult,
} from 'src/actions/queries/orderQueries';

const CREATE_BOOK_COUPON_QUERY = `mutation createBookCoupon($draftId: ID!) {
  createBookCoupon(input: { draftId: $draftId }) {
    success
  }
}`;

interface CreateBookCouponParams {
  draftId: string;
}

interface CreateBookCouponResult {
  createBookCoupon: {
    success: boolean;
  };
}

const GiftPaymentCallbackPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const showToast = useToast();
  const isCalled = useRef(false);

  const {
    isLoading: isFetching,
    error: fetchError,
    result: fetchResult,
    executeQuery: fetchDraftBookCouponLetter,
  } = useQuery<GetDraftBookCouponLetterParams, GetDraftBookCouponLetterResult>(GET_DRAFT_BOOK_COUPON_LETTER_QUERY);

  const { executeMutation: createBookCoupon, isLoading: isCreating } = useMutation<
    CreateBookCouponParams,
    CreateBookCouponResult
  >();

  const draftId = searchParams.get('draft_id');
  const draftBookCouponLetter = fetchResult?.draftBookCouponLetter ?? null;
  const shipmentType = draftBookCouponLetter?.shipmentType ?? null;

  useEffect(() => {
    fetchDraftBookCouponLetter({ id: draftId ?? '' });
  }, [fetchDraftBookCouponLetter, draftId]);

  useEffect(() => {
    if (!draftId || !shipmentType) return;

    const run = async () => {
      if (isCalled.current) return;

      isCalled.current = true;

      const result = await createBookCoupon(CREATE_BOOK_COUPON_QUERY, { draftId });

      if (result?.createBookCoupon.success) {
        switch (shipmentType) {
          case 'email':
            GATracker.trackEvent('send_gift');
            break;
          case 'other':
            GATracker.trackEvent('send_gift_by_post');
            break;
          case 'self':
            GATracker.trackEvent('send_gift_by_hand');
            break;
          default:
            return;
        }

        const queryParams = new URLSearchParams({ draftId });
        const thanksUrl = ['/mypage/gifts/thanks', queryParams.toString()].join('?');
        navigate(thanksUrl);
      }
    };

    run();
  }, [navigate, showToast, createBookCoupon, draftId, shipmentType]);

  const onClickRetry = () => {
    const queryParams = new URLSearchParams({ draftId: draftId ?? '' });
    const retryUrl = ['/mypage/gifts/payment/confirmation', queryParams.toString()].join('?');
    navigate(retryUrl);
  };

  return (
    <AppTemplate>
      <UiStackTemplate isLoading={isFetching || isCreating} error={fetchError}>
        <PaymentAuthenticationFailure onClick={onClickRetry} />
      </UiStackTemplate>
    </AppTemplate>
  );
};

export default GiftPaymentCallbackPage;
