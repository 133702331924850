import Button from 'src/components/atoms/button/Button';

import CSSModule from './PaymentAuthenticationFailure.module.scss';

interface Props {
  onClick: () => void;
}

const PaymentAuthenticationFailure: React.FC<Props> = ({ onClick }) => {
  return (
    <div className={CSSModule.PaymentAuthenticationFailure}>
      <p>3Dセキュア認証に失敗しました。再度ご注文お願いします。</p>
      <div className={CSSModule.PaymentAuthenticationFailure__Button}>
        <Button onClick={onClick}>再注文</Button>
      </div>
    </div>
  );
};

export default PaymentAuthenticationFailure;
