import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Button from '../atoms/button/Button';
import Divider from '../atoms/divider/Divider';
import Alert from '../atoms/text/Alert';
import PriceConfirmation from '../payment/PriceConfirmation';

import CSSModule from './ByHandsGiftForm.module.scss';

interface Props {
  postalCode: string;
  address: string;
  disabled: boolean;
  serverError: string | null;
  onSubmit: () => void;
}

const ByHandsGiftForm: React.FC<Props> = ({ postalCode, address, disabled, serverError, onSubmit }) => {
  const { handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={CSSModule.ByHandsGiftForm}>
      <p className={CSSModule.ByHandsGiftForm__Description}>
        ご登録いただいている下記の住所に
        <br />
        ギフトカードを郵送いたします。
      </p>
      <div className={CSSModule.ByHandsGiftForm__AddressConfirmation}>
        <Divider />
        <p className={CSSModule.ByHandsGiftForm__Address}>
          〒{postalCode}
          <br />
          {address}
        </p>
        <Divider />
      </div>
      <Link to="/mypage/profile" className={CSSModule.ByHandsGiftForm__ChangeAddressLink}>
        送り先住所を変更する
      </Link>
      <div className={CSSModule.ByHandsGiftForm__Price}>
        <PriceConfirmation />
      </div>
      {serverError && <Alert message={serverError} />}
      <div className={CSSModule.ByHandsGiftForm__Button}>
        <Button color="primary" disabled={disabled}>
          決済に進む
        </Button>
        <Link to="/mypage/profile" className={CSSModule.ByHandsGiftForm__CardRegistrationLink}>
          カード情報の登録・変更
        </Link>
      </div>
    </form>
  );
};

export default ByHandsGiftForm;
