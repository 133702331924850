import React from 'react';

import { UserListItem } from 'src/interfaces/user';
import ListPageTemplate from 'src/components/admin/common/ListPageTemplate';
import AdminUserList from 'src/components/admin/users/AdminUserList';

interface Props {
  isLoading: boolean;
  error: Error | null;
  users: UserListItem[];
  page: number;
  size: number;
  totalCount: number;
  onChangePage: (page: number) => void;
  onClickRow: (userId: string) => void;
}

const AdminUserListPage: React.FC<Props> = ({
  users,
  isLoading,
  error,
  page,
  size,
  totalCount,
  onChangePage,
  onClickRow,
}) => (
  <ListPageTemplate
    title="ユーザー一覧"
    isLoading={isLoading}
    error={error}
    page={page}
    size={size}
    totalCount={totalCount}
    isEmpty={!!users && users.length === 0}
    onChangePage={onChangePage}
  >
    <AdminUserList users={users} onClickRow={onClickRow} />
  </ListPageTemplate>
);

export default AdminUserListPage;
