import React from 'react';
import { Link } from 'react-router-dom';

import MainLogo from '../../../assets/logo/main.png';

import CSSModule from './AdminHeader.module.scss';

const AdminHeader: React.FC = () => (
  <div className={CSSModule.adminHeader}>
    <div className={CSSModule.contentWrapper}>
      <Link to="/admin" className={CSSModule.logoWrapper}>
        <img src={MainLogo} alt="ブックカルテ" className={CSSModule.logo} />
      </Link>
      <div className={CSSModule.menuList}>
        <Link to="/admin" className={CSSModule.menuListItem}>
          <div className={CSSModule.linkText}>書店員管理</div>
        </Link>
        <Link to="/admin/orders" className={CSSModule.menuListItem}>
          <div className={CSSModule.linkText}>注文管理</div>
        </Link>
        <Link to="/admin/users" className={CSSModule.menuListItem}>
          <div className={CSSModule.linkText}>ユーザー管理</div>
        </Link>
        <Link to="/admin/gifts" className={CSSModule.menuListItem}>
          <div className={CSSModule.linkText}>ギフトコード管理</div>
        </Link>
      </div>
    </div>
  </div>
);

export default AdminHeader;
