import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Link } from 'react-router-dom';

import { BookCoupon } from 'src/interfaces/order';

interface Props {
  bookCoupons: BookCoupon[];
}

const BookCouponList: React.FC<Props> = ({ bookCoupons }) => {
  return (
    <table className="table table-bordered mt-3">
      <thead>
        <tr>
          <th>ID</th>
          <th>ギフトコード</th>
          <th>発行日</th>
          <th>発行者</th>
          <th>注文者</th>
          <th>注文日</th>
        </tr>
      </thead>
      <tbody>
        {bookCoupons.map(coupon => (
          <tr key={coupon.id}>
            <td>{coupon.id}</td>
            <td>{coupon.code}</td>
            <td>{format(parseISO(coupon.createdAt), 'yyyy/MM/dd hh:mm')}</td>
            <td>
              {coupon.issuer ? (
                <Link to={`/admin/users/${coupon.issuer.id}`}>
                  <span>{coupon.issuer.name}</span>
                  <span>（ID: {coupon.issuer.id}）</span>
                </Link>
              ) : (
                '退会済みユーザー'
              )}
            </td>
            <td>
              {!coupon.recipient && '未使用'}
              {coupon.recipient && coupon.bookOrder && (
                <Link to={`/admin/orders/${coupon.bookOrder.id}/edit`}>
                  {coupon.recipient.name}（注文ID: {coupon.bookOrder.id}）
                </Link>
              )}
            </td>
            <td>
              {coupon.bookOrder?.orderedAt ? format(parseISO(coupon.bookOrder.orderedAt), 'yyyy/MM/dd hh:mm') : '-'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BookCouponList;
