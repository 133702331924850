import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useQuery from 'src/hooks/common/useQuery';
import useToast from 'src/hooks/store/useToast';
import {
  GET_DRAFT_BOOK_COUPON_LETTER_QUERY,
  GetDraftBookCouponLetterParams,
  GetDraftBookCouponLetterResult,
} from 'src/actions/queries/orderQueries';
import AppTemplate from 'src/components/templates/AppTemplate';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import BookCouponDetail from 'src/components/gifts/BookCouponDetail';
import Authenticated from 'src/containers/common/Authenticated';
import useMutation from 'src/hooks/common/useMutation';

const CREATE_BOOK_COUPON_PAYMENT_QUERY = `mutation createBookCouponPayment($draftId: ID!) {
  createBookCouponPayment(input: { draftId: $draftId }) {
    success
    redirectUrl
  }
}`;

interface CreateBookCouponPaymentParams {
  draftId: string;
}

interface CreateBookCouponPaymentResult {
  createBookCouponPayment: {
    success: boolean;
    redirectUrl: string | null;
  };
}

const GiftPaymentConfirmationPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const showToast = useToast();

  const {
    isLoading: isFetching,
    error: fetchError,
    result: fetchResult,
    executeQuery: fetchDraftBookCouponLetter,
  } = useQuery<GetDraftBookCouponLetterParams, GetDraftBookCouponLetterResult>(GET_DRAFT_BOOK_COUPON_LETTER_QUERY);

  const { isLoading: isCreating, executeMutation: createBookCouponPayment } = useMutation<
    CreateBookCouponPaymentParams,
    CreateBookCouponPaymentResult
  >();

  const draftId = searchParams.get('draftId');
  const draftBookCouponLetter = fetchResult?.draftBookCouponLetter ?? null;

  useEffect(() => {
    if (!draftId) return;

    fetchDraftBookCouponLetter({ id: draftId });
  }, [fetchDraftBookCouponLetter, draftId]);

  const onSubmit = async () => {
    const result = await createBookCouponPayment(CREATE_BOOK_COUPON_PAYMENT_QUERY, { draftId: draftId ?? '' });

    if (result?.createBookCouponPayment.redirectUrl) {
      // Stripe requires 3D Secure authentication
      window.location.assign(result.createBookCouponPayment.redirectUrl);
    } else if (result?.createBookCouponPayment.success) {
      const queryParams = new URLSearchParams({ draft_id: draftId ?? '' });
      const callbackUrl = ['/mypage/gifts/payment/callback', queryParams.toString()].join('?');
      navigate(callbackUrl);
    } else {
      showToast('予期しないエラーが発生しました。');
    }
  };

  return (
    <AppTemplate>
      <Authenticated>
        <UiStackTemplate isLoading={isFetching} error={fetchError}>
          {draftBookCouponLetter && (
            <BookCouponDetail draftBookCouponLetter={draftBookCouponLetter} disabled={isCreating} onSubmit={onSubmit} />
          )}
        </UiStackTemplate>
      </Authenticated>
    </AppTemplate>
  );
};

export default GiftPaymentConfirmationPage;
