import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import API from './API';
import {
  REGISTER,
  LOGIN,
  FETCH_ME,
  UPDATE_USER,
  FETCH_URL_PRESIGNED_URL,
  UPLOAD_USER_PHOTO,
  LOGOUT,
  UNREGISTER,
  UPDATE_CURRENT_USER,
} from './actionTypes';
import { User } from '../interfaces/user';
import {
  registerQuery,
  getAccessTokenQuery,
  getMeQuery,
  updateUserQuery,
  getUserPresignedUrlQuery,
  unregisterQuery,
} from './queries/authQueries';
import GATracker from '../utils/GATracker';

const actionCreator = actionCreatorFactory();
const asyncActionCreator = asyncFactory(actionCreator);

interface SignUpParams {
  user: {
    name: string;
    nameKana: string;
    email: string;
    tel: string;
    postalCode: string;
    address: string;
    shippingPostalCode: string | null;
    shippingAddress: string | null;
    photoFilename?: string | null;
    stripeCardId?: string;
  };
}

interface SignUpResult {
  token: string;
}

export const register = asyncActionCreator<SignUpParams, SignUpResult>(REGISTER, async ({ user }) => {
  const { registerUser: result } = await API.query(registerQuery, { user: { ...user } });

  if (result.error) {
    return Promise.reject(new Error(result.error));
  }

  return result;
});

interface LoginParams {
  email: string;
  password: string;
}

interface LoginResult {
  token: string;
}

export const login = asyncActionCreator<LoginParams, LoginResult>(LOGIN, async ({ email, password }) => {
  const {
    accessToken: { token },
  } = await API.query(getAccessTokenQuery, {
    email,
    password,
  });
  return { token };
});

export const logout = actionCreator(LOGOUT);

export const unregisterUser = asyncActionCreator<void, void>(UNREGISTER, async () => {
  const {
    unregisterUser: { error },
  } = await API.query(unregisterQuery);

  GATracker.trackEvent('withdraw');

  if (error) {
    throw new Error(error);
  }
});

interface FetchMeResult {
  me: User | null;
}

export const fetchMe = asyncActionCreator<void, FetchMeResult>(FETCH_ME, () => API.query(getMeQuery));

interface UpdateUserParams {
  user: Partial<User>;
}

interface UpdateUserResult {
  error: string | null;
  user: User | null;
}

export const updateUser = asyncActionCreator<UpdateUserParams, UpdateUserResult>(UPDATE_USER, async ({ user }) => {
  const { updateUser: result } = await API.query(updateUserQuery, { user: { ...user, agreeTerms: true } });
  return result;
});

interface FetchUserPresignedUrlParams {
  extname: string;
}

interface FetchUserPresignedUrlResult {
  presignedUrl: string;
  filename: string;
  expiresAt: string;
}

export const fetchUserPresignedUrl = asyncActionCreator<FetchUserPresignedUrlParams, FetchUserPresignedUrlResult>(
  FETCH_URL_PRESIGNED_URL,
  async params => {
    const { userPresignedUrl: result } = await API.query(getUserPresignedUrlQuery, params);
    return result;
  },
);

interface UploadUserPhotoParams {
  presignedUrl: string;
  file: File;
}

export const uploadUserPhoto = asyncActionCreator<UploadUserPhotoParams, void>(
  UPLOAD_USER_PHOTO,
  ({ file, presignedUrl }) =>
    fetch(presignedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Encoding': 'base64',
        'Content-Type': file.type,
      },
    }),
);

interface UpdateCurrentUserParams {
  user: User | null;
}

export const updateCurrentUser = actionCreator<UpdateCurrentUserParams>(UPDATE_CURRENT_USER);
