import React from 'react';
import { Link } from 'react-router-dom';

import {
  BookOrder,
  SelectedBookFieldValue,
  BookOrderStatusLabel,
  isOrderedBookOrder,
  isRejectedBookOrder,
} from 'src/interfaces/order';
import Button from 'src/components/atoms/button/Button';
import SelectedBooksForm from 'src/components/admin/orders/SelectedBooksForm';
import SelectedBookListItem from 'src/components/admin/orders/SelectedBookListItem';

import CSSModule from './EditOrderPage.module.scss';

interface Props {
  bookOrder: BookOrder;
  selectedBooks: SelectedBookFieldValue[];
  disabled: boolean;
  error: Error | null;
  onChangeBooks: (selectedBooks: SelectedBookFieldValue[]) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const EditOrderPage: React.FC<Props> = ({
  bookOrder,
  selectedBooks,
  disabled,
  error,
  onChangeBooks,
  onSubmit,
  onCancel,
}) => {
  return (
    <div className={CSSModule.editOrderPage}>
      <h1 className={CSSModule.title}>選書リスト編集</h1>
      <dl className={CSSModule.detail}>
        <dt>注文者</dt>
        <dd>
          {bookOrder.user ? (
            <Link to={`/admin/users/${bookOrder.user.id}`}>{bookOrder.user.name}</Link>
          ) : (
            '退会済ユーザー'
          )}
        </dd>
        <dt>書店員</dt>
        <dd>
          <Link to={`/admin/staffs/${bookOrder.staff.id}/edit`}>
            {bookOrder.staff.name}（{bookOrder.staff.bookStoreName}）
          </Link>
        </dd>
        <dt>ステータス</dt>
        <dd>{BookOrderStatusLabel[bookOrder.status]}</dd>
      </dl>
      <hr />
      {isOrderedBookOrder(bookOrder) || isRejectedBookOrder(bookOrder) ? (
        <SelectedBooksForm
          selectedBooks={selectedBooks}
          disabled={disabled}
          error={error}
          onChangeBooks={onChangeBooks}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : (
        <>
          {bookOrder.selectedBooks.length > 0 && (
            <div>
              <h2 className={CSSModule.subtitle}>選書リスト</h2>
              {bookOrder.selectedBooks.map(selectedBook => (
                <SelectedBookListItem key={selectedBook.id} selectedBook={selectedBook} />
              ))}
            </div>
          )}
          <div>{BookOrderStatusLabel[bookOrder.status]}のため選書リストを編集できません。</div>
          <div className={CSSModule.buttons}>
            <div className={CSSModule.button}>
              <Button color="default" onClick={onCancel}>
                戻る
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditOrderPage;
