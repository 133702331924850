import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { EmailRegexp } from '../../app.constants';

import Button from '../atoms/button/Button';
import Input from '../atoms/form/Input';
import TextArea from '../atoms/form/TextArea';
import Alert from '../atoms/text/Alert';
import PriceConfirmation from '../payment/PriceConfirmation';

import CSSModule from './GiftForm.module.scss';

export interface FormValues {
  recipientName: string;
  email: string;
  message: string;
}

interface Props {
  initialValues: Partial<FormValues>;
  disabled: boolean;
  serverError: string | null;
  onSubmit: (values: FormValues) => void;
}

const GiftForm: React.FC<Props> = ({ initialValues, disabled, serverError, onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={CSSModule.GiftForm}>
      <div className="form-group">
        <Input
          {...register('recipientName', {
            required: '送り先名をご入力ください。',
          })}
          id="gift[recipientName]"
          required
          label="送り先名"
          placeholder="例）本田　太郎"
        />
        {errors.recipientName && <Alert message={errors.recipientName.message} />}
      </div>
      <div className="form-group">
        <Input
          id="gift[email]"
          {...register('email', {
            required: 'メールアドレスをご入力ください。',
            pattern: {
              value: EmailRegexp,
              message: 'メールアドレスの形式が不正です。',
            },
          })}
          required
          label="メールアドレス"
          placeholder="例）book-karte@example.com"
        />
        {errors.email && <Alert message={errors.email.message} />}
      </div>
      <div className="form-group">
        <label htmlFor="gift[message]" className={CSSModule.GiftForm__Label}>
          メッセージ
        </label>
        <div className={CSSModule.GiftForm__MessageDescription}>
          ギフトの送り先に弊社規定のメッセージに加えてお伝えしたいことがあればご記入ください。
        </div>
        <TextArea {...register('message')} id="gift[message]" placeholder="メッセージを記入する" />
      </div>
      <div className={CSSModule.GiftForm__Price}>
        <PriceConfirmation />
      </div>
      {serverError && <Alert message={serverError} />}
      <div className={CSSModule.GiftForm__Button}>
        <Button color="primary" disabled={disabled}>
          決済に進む
        </Button>
        <Link to="/mypage/profile" className={CSSModule.GiftForm__CardRegistrationLink}>
          カード情報の登録・変更
        </Link>
      </div>
    </form>
  );
};

export default GiftForm;
