import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import {
  getDraftBookOrderQuery,
  PostBookOrderParams,
  PostBookOrderResult,
  postOrderQuery,
} from 'src/actions/queries/orderQueries';
import { TotalProgressNumber } from 'src/containers/orders/NewOrderContainer';
import Authenticated from 'src/containers/common/Authenticated';
import OrderPaymentMethodPage, { PaymentMethod } from 'src/components/orders/OrderPaymentMethodPage';
import OrderConfirmationPage from 'src/components/orders/OrderConfirmationPage';
import ProgressBarTemplate from 'src/components/templates/ProgressBarTemplate';
import UiStackTemplate from 'src/components/common/UiStackTemplate';
import useQuery from 'src/hooks/common/useQuery';
import useMutation from 'src/hooks/common/useMutation';
import useCurrentUser from 'src/hooks/store/useCurrentUser';
import useToast from 'src/hooks/store/useToast';
import { BookOrder, BookOrderStatus } from 'src/interfaces/order';

interface GetDraftBookOrderParams {
  staffId: string;
  userId: string;
  status: BookOrderStatus[];
  size: number;
}

interface GetDraftBookOrderResult {
  bookOrders: BookOrder[];
}

const CreateBookOrderPaymentQuery = `mutation createBookOrderPayment($staffId: ID!, $couponCode: String) {
  createBookOrderPayment(input: { staffId: $staffId, couponCode: $couponCode }) {
    success
    paymentIntentId
    redirectUrl
  }
}`;

interface CreateBookOrderPaymentParams {
  staffId: string;
  couponCode: string | null;
}

interface CreateBookOrderPaymentResult {
  createBookOrderPayment: {
    success: boolean;
    paymentIntentId: string | null;
    redirectUrl: string | null;
  };
}

const OrderPaymentPage: React.FC = () => {
  const { id: staffId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const showToast = useToast();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [currentUser] = useCurrentUser();
  const {
    result: bookOrderResult,
    isLoading: isFetchingOrder,
    error: fetchOrderError,
    executeQuery: fetchOrder,
  } = useQuery<GetDraftBookOrderParams, GetDraftBookOrderResult>(getDraftBookOrderQuery);

  const {
    executeMutation: createBookOrder,
    isLoading: isCreatingBookOrder,
    error: createBookOrderError,
  } = useMutation<PostBookOrderParams, PostBookOrderResult>();

  const {
    executeMutation: createBookOrderPayment,
    isLoading: isCreatingPayment,
    error: createPaymentError,
  } = useMutation<CreateBookOrderPaymentParams, CreateBookOrderPaymentResult>();

  const bookOrder = bookOrderResult?.bookOrders?.[0] ?? null;
  const userId = currentUser?.id ?? null;

  useEffect(() => {
    if (bookOrderResult && !bookOrder) {
      navigate(`/staffs/${staffId}/orders/new`);
    }
  }, [bookOrderResult, bookOrder, staffId, navigate]);

  useEffect(() => {
    if (!staffId || !userId) {
      return;
    }

    fetchOrder({
      staffId,
      userId,
      status: [BookOrderStatus.Draft],
      size: 1,
    });
  }, [staffId, userId, fetchOrder]);

  const goBackToKartePage = () => {
    navigate(`/staffs/${staffId}/orders/new`);
  };

  const selectPaymentMethod = (selectedPaymentMethod: PaymentMethod) => {
    setPaymentMethod(selectedPaymentMethod);
  };

  const goBackToPaymentMethodSelect = () => {
    setPaymentMethod(null);
  };

  const onSubmit = async () => {
    if (!staffId || !bookOrder) {
      return;
    }

    const createBookOrderResult = await createBookOrder(postOrderQuery, {
      staffId,
      isDraft: true,
      karte: {
        gender: bookOrder.karte.gender,
        age: bookOrder.karte.age,
        answer1: bookOrder.karte.answer1,
        answer2: bookOrder.karte.answer2,
        answer3: bookOrder.karte.answer3,
        answer4: bookOrder.karte.answer4,
        answer5: bookOrder.karte.answer5,
        answer6: bookOrder.karte.answer6,
        answer7: bookOrder.karte.answer7,
        answer8: bookOrder.karte.answer8,
        answer9: bookOrder.karte.answer9,
        answer10: bookOrder.karte.answer10,
        karteQuestionTemplateId: bookOrder.karte.karteQuestionTemplate.id,
      },
      couponCode: null,
    });

    if (createBookOrderResult?.createBookOrder.error) {
      showToast(createBookOrderResult.createBookOrder.error);
      return;
    }

    const couponCode = paymentMethod?.paymentMethodType === 'coupon' ? paymentMethod.couponCode : null;

    const createPaymentResult = await createBookOrderPayment(CreateBookOrderPaymentQuery, {
      staffId,
      couponCode,
    });

    if (createPaymentResult?.createBookOrderPayment.redirectUrl) {
      window.location.assign(createPaymentResult.createBookOrderPayment.redirectUrl);
      return;
    }

    if (createBookOrderResult?.createBookOrder.bookOrder && createPaymentResult?.createBookOrderPayment.success) {
      // Note: paymentIntentId can be null when gift code is used
      const callbackParams = new URLSearchParams({
        staff_id: staffId,
        payment_intent: createPaymentResult.createBookOrderPayment.paymentIntentId ?? '',
      });
      const callbackUrl = ['/orders/payment/callback', callbackParams.toString()].join('?');
      navigate(callbackUrl);
    }
  };

  const progressPosition = paymentMethod ? 3 : 2;

  return (
    <ProgressBarTemplate title="決済方法選択" position={progressPosition} total={TotalProgressNumber}>
      <Authenticated>
        <UiStackTemplate isLoading={isFetchingOrder} error={fetchOrderError}>
          {paymentMethod === null && staffId && (
            <OrderPaymentMethodPage staffId={staffId} onSubmit={selectPaymentMethod} onCancel={goBackToKartePage} />
          )}
          {paymentMethod && currentUser && (
            <OrderConfirmationPage
              paymentType={paymentMethod.paymentMethodType}
              couponCode={paymentMethod.couponCode}
              user={currentUser}
              disabled={isCreatingBookOrder || isCreatingPayment}
              serverError={createBookOrderError?.message ?? createPaymentError?.message ?? null}
              onSubmit={onSubmit}
              onCancel={goBackToPaymentMethodSelect}
            />
          )}
        </UiStackTemplate>
      </Authenticated>
    </ProgressBarTemplate>
  );
};

export default OrderPaymentPage;
