import React from 'react';
import {
  DraftBookCouponLetter,
  DraftBookCouponLetterEmailType,
  DraftBookCouponLetterOtherType,
  DraftBookCouponLetterSelfType,
} from 'src/interfaces/order';
import PriceConfirmation from 'src/components/payment/PriceConfirmation';
import Button from 'src/components/atoms/button/Button';

import CSSModule from './BookCouponDetail.module.scss';

interface Props {
  draftBookCouponLetter: DraftBookCouponLetter;
  disabled: boolean;
  onSubmit: () => void;
}

const BookCouponDetail: React.FC<Props> = ({ draftBookCouponLetter, disabled, onSubmit }) => {
  return (
    <div className={CSSModule.BookCouponDetail}>
      <BookCouponDetailTable draftBookCouponLetter={draftBookCouponLetter} />
      <PriceConfirmation />
      <Button disabled={disabled} onClick={onSubmit}>
        決済を行う
      </Button>
    </div>
  );
};

export default BookCouponDetail;

interface DetailTableProps {
  draftBookCouponLetter: DraftBookCouponLetter;
}

export const BookCouponDetailTable: React.FC<DetailTableProps> = ({ draftBookCouponLetter }) => {
  switch (draftBookCouponLetter.shipmentType) {
    case 'email':
      return <EmailTypeDetail draftBookCouponLetter={draftBookCouponLetter} />;
    case 'self':
      return <SelfTypeDetail draftBookCouponLetter={draftBookCouponLetter} />;
    case 'other':
      return <OtherTypeDetail draftBookCouponLetter={draftBookCouponLetter} />;
    default:
      return null;
  }
};

interface EmailTypeDetailProps {
  draftBookCouponLetter: DraftBookCouponLetterEmailType;
}

const EmailTypeDetail: React.FC<EmailTypeDetailProps> = ({ draftBookCouponLetter }) => {
  return (
    <dl>
      <dt>お届け方式</dt>
      <dd>メールアドレス宛にギフトコードを送信いたします。</dd>
      <dt>送り先名</dt>
      <dd>{draftBookCouponLetter.recipientName} 様</dd>
      <dt>送り先メールアドレス</dt>
      <dd>{draftBookCouponLetter.email}</dd>
      <dt>メッセージ</dt>
      <dd>{draftBookCouponLetter.message || '記入なし'}</dd>
    </dl>
  );
};

interface SelfTypeDetailProps {
  draftBookCouponLetter: DraftBookCouponLetterSelfType;
}

const SelfTypeDetail: React.FC<SelfTypeDetailProps> = ({ draftBookCouponLetter }) => {
  return (
    <dl>
      <dt>お届け方式</dt>
      <dd>ご自宅にギフトコードを郵送いたします。</dd>
      <dt>郵送先</dt>
      <dd>
        〒{draftBookCouponLetter.shippingAddress.postalCode}
        <br />
        {draftBookCouponLetter.shippingAddress.address}
      </dd>
    </dl>
  );
};

interface OtherTypeDetailProps {
  draftBookCouponLetter: DraftBookCouponLetterOtherType;
}

const OtherTypeDetail: React.FC<OtherTypeDetailProps> = ({ draftBookCouponLetter }) => {
  return (
    <dl>
      <dt>お届け方式</dt>
      <dd>ご入力いただいたご住所宛にギフトコードを郵送いたします。</dd>
      <dt>送り先</dt>
      <dd>{draftBookCouponLetter.recipientName} 様</dd>
      <dt>郵送先</dt>
      <dd>
        〒{draftBookCouponLetter.shippingAddress.postalCode}
        <br />
        {draftBookCouponLetter.shippingAddress.address}
      </dd>
    </dl>
  );
};
